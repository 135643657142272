import styled, { css } from 'styled-components';

import { media } from '@hl-portals/helpers';

import { Box } from '../Box';
import { ButtonToggle } from '../ButtonToggle';
import { Icon } from '../Icon';

export const AccordionButton = styled(ButtonToggle)<{
  iconPosition: 'left' | 'right';
  fontColor?: string;
  fontWeight?: string;
}>`
  ${({ iconPosition, fontColor, fontWeight }) =>
    iconPosition === 'left' &&
    css`
      margin-left: 0.5rem;
      padding-left: 1rem;
    `};

  display: flex;
  justify-content: space-between;
  align-items: center;
  font: unset;
  color: ${({ fontColor }) => fontColor || 'unset'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem;
  width: 100%;
  text-align: inherit;
  label {
    cursor: pointer;
  }
  ${media.extraSmall`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  `}
`;
export const AccordionIcon = styled(Icon)<{ open: boolean }>`
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-50%)
    ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const AccordionContent = styled(Box)<{
  open: boolean;
  iconPosition: 'left' | 'right';
}>`
  width: 100%;
  ${({ open, iconPosition }) => css`
    ${!!open && 'margin-top: 12px;'}
    height: ${open ? 'auto' : '0'};
    opacity: ${open ? '1' : '0'};
    overflow: hidden;
    padding-left: ${iconPosition === 'left' ? '1rem' : '0.5rem'};
    transition: all 300ms ease-in-out;
  `};
`;
